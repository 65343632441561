'use client'
import CustomNotFound from '@/components/UI/common/notFound/CustomNotFound'
import { useEffect } from 'react'

type Props = {
    error: Error & { digest?: string }
    reset: () => void
}

export default function Error({ error, reset }: Props) {

    useEffect(() => {
    }, [error])

    return (
        <CustomNotFound msg={"هناك عطل في هذه الصفحة ونعمل حاليا علي إصلاحه"} />
    )
}