import Link from "next/link";

type Props = {
    text: any;
    link?: string;
    icon?: any
    newClass?: string;
    onHandleClick?: () => void,
    progress?: number
};

function SolidLink({ text, link, newClass, icon, onHandleClick, progress }: Props) {

    return (
        <>
            {link &&
                <a href={link} className={`solid-common-btn ${newClass}`} onClick={onHandleClick}>
                    {text}
                    {icon}
                    {progress &&
                        <div className="progress-bar-image">
                            <div className="progress-bar-image-child" style={{ width: `${progress < 100 ? progress : 0}px` }}></div>
                        </div>
                    }
                </a>
            }
            {!link &&
                <button className={`solid-common-btn ${newClass}`} onClick={onHandleClick}>
                    {text}
                    {icon}
                    {progress &&
                        <div className="progress-bar-image">
                            <div className="progress-bar-image-child" style={{ width: `${progress < 100 ? progress : 0}px` }}></div>
                        </div>
                    }
                </button>
            }
        </>

    );
}

export default SolidLink;
