import SolidLink from "../buttonsOrLinks/SolidLink";

type Props = {
    msg?: string
}
export default function CustomNotFound({ msg }: Props) {
    return (
        <main id="not-found-page">
            <div className="container">
                <div className="not-found-box">
                    <p>{msg ? msg : "هذه الصفحة غير موجودة"}</p>
                    <SolidLink text={'العودة إلي الصفحة الرئيسية'} link={"/"} newClass={"go-home-link"} />
                </div>
            </div>
        </main>
    );
}
